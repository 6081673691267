import { useErrorMonitor } from '@wix/yoshi-flow-editor';

import { getUploadUrl, IUploadResponse } from 'api/media-manager';

import { useHttpClient } from './useHttpClient';

export function useFileUploader() {
  const errorMonitor = useErrorMonitor();
  const httpClient = useHttpClient();

  return {
    async upload(file: File) {
      const { data: url } = await httpClient.request(
        getUploadUrl(file.name, file.type, file.size),
      );

      const data = new FormData();
      data.append('file', file, file.name);

      try {
        const response = await httpClient.post<IUploadResponse[]>(url, data, {
          headers: { 'Content-Type': 'multipart/form-data;' },
        });

        return response.data[0];
      } catch (err) {
        errorMonitor.captureException(err as Error);
        console.error(err);
        return Promise.reject(err);
      }
    },
  };
}
